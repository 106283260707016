import React, { useState, useCallback } from 'react'
import fetch from 'unfetch'
import { useLoads } from 'react-loads'
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Link,
  Stack,
  Box,
  Flex,
} from '@chakra-ui/react'
import 'react-phone-number-input/style.css'
import PhoneInput, {
  isValidPhoneNumber,
} from 'react-phone-number-input'
import { ErrorHandling } from '../utils/error'



const ContactForm = ({colorScheme = "brand.steak", inline = false, showLabel = true, emailId = 'k_id_email2'}) => {
  const form = React.createRef() as React.RefObject<HTMLFormElement>
  const [attempts, setAttempts] = useState(0)
  const [value, setValue] = useState('')
  const [processingMessage, setProcessingMessage] = useState('')
  const [loading, setLoading] = useState(false)


  function wait(ms = 0) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, ms)
    })
  }

  const handleSubscribe = useCallback(
    async (email: any, token: any, phone: any, toggleCheck: boolean) => {
      setAttempts(attempts + 1)
      setLoading(true)
      setProcessingMessage('Sending')

      const res = await fetch(
        `${process.env.GATSBY_SERVERLESS_BASE}/whatsapp-klaviyo-subscribe`,
        {
          method: 'POST',
          body: JSON.stringify({
            email,
            token,
            phone
          }),
        }
      )
      try {
        const response = await res.json()

        if (response.error) {
          throw new Error(response.error)
        } else if( response == "Failed") {

          setProcessingMessage('Something went wrong, please try again')
          await wait(7000)
          setLoading(false)
        } else {
          
          setProcessingMessage('Thank you for subscribing, please check your email to confirm')
          await wait(7000)
          setLoading(false)

        }
      } catch (err) {
        
        throw err
      }
    },
    [attempts]
  )
  const { error, isRejected, isPending, isReloading, load } = useLoads(
    'handleSubscribe',
    handleSubscribe as any,
    {
      defer: true,
    }
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget);
    const token = formData.get('cf-turnstile-response') as string;
    const { email, phone } =
      form!.current!.elements
    load(
      email.value, token, phone.value
    )
  }
  return (
    <Box>
      
      <form onSubmit={(e) => handleSubmit(e)} ref={form}>
        <Stack spacing={4}>

         

          {isRejected && <ErrorHandling error={error.message} />}

            <FormControl mb={inline ? 0 : 4} isRequired>
             {showLabel &&<FormLabel htmlFor="email" fontWeight="600">Email (required)</FormLabel>}

              <Input
                type="text"
                variant="filled"
                name="email"
                id={emailId}
                borderRadius={inline ? 0 : 4}
                placeholder={'your@email.com'}
                autoComplete="email"
              />
            </FormControl>
 
            <FormControl mb={4}>
              <FormLabel htmlFor="phone" fontWeight="600" fontSize="sm">Whatsapp/SMS (optional)<Text fontWeight={"400"} fontSize="sm">{`(...don't miss a crumb! No spam, just hand-picked updates that matter to you)`}</Text></FormLabel>
              

              <Input
                as={PhoneInput}
                variant="filled"
                _placeholder={{ opacity: 0.5, color: 'gray.400'}}
                name="phone"
                id="phone"
                placeholder={'Your phone number'}
                value={value}
                international={true}
                defaultCountry="GB"
                withCountryCallingCode={true}
                onChange={() => setValue}
                error={
                  isValidPhoneNumber(value)
                      ? undefined
                      : 'Invalid phone number'
                    
                }
              />

            </FormControl>

          <Flex alignItems="center" flexDirection="column">
            <Button
              type="submit"
              colorScheme={colorScheme}
              isLoading={loading}
              loadingText='Sending'
              pr={4}
              pl={4}
              mt={inline ? 0 : 1}
              pt={inline ? 0 : 1}
              width="100%"
            >
              <Text>{"Subscribe"}</Text>
            </Button>
          </Flex>
          
        </Stack>
        <Text align={"center"}>{loading && processingMessage}</Text>
        <div className="cf-turnstile" data-sitekey="0x4AAAAAAAVX2BYOL1V8-uf0"></div>

      </form>
      {!inline && <Text fontSize="sm" mt={4}>If you ever want to step back from the pie party, simply click the unsubscribe link. For all the fine print, check out our <Link variant="inline" href="/privacy-policy">Privacy Policy</Link> & <Link variant="inline"  href="/terms-of-service">Terms</Link>.</Text>}

      </Box>
  )
}



export default ContactForm
